import React from "react";
import "./App.css";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Login from "./Components/Login";
import Landing from "./Components/Landing";
import "@progress/kendo-theme-bootstrap/dist/all.css";
import { Helmet } from "react-helmet";

function App() {
	return (
		<div className="App">
			<Helmet>
				<title>Carter Admin</title>
				<meta name="description" content="Carter Admin site" />
				<meta name="theme-color" content="#F8B033" />
			</Helmet>
			<Router>
				<Route path="/" exact component={Login} />
				<Route path="/Landing" exact component={Landing} />
			</Router>
		</div>
	);
}

export default App;
