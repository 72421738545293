import React, { Component } from "react";
import ReactDOM from "react-dom";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { DataLoader } from "./products-loader.jsx";
import "./gridStyles.css";

export default class CarterGrid extends Component {
	constructor(props) {
		super(props);
		this.state = {
			hasError: false,
			products: { data: [], total: 0 },
			dataState: { take: 10, skip: 0 },
		};
	}

	dataStateChange = (e) => {
		this.setState({
			...this.state,
			dataState: e.data,
		});
	};

	dataRecieved = (products) => {
		this.setState({
			...this.state,
			products: products,
		});
	};

	cellWithBackGround = (props) => {
		let product = props.dataItem.LastRun;
		const MaxTime = props.dataItem.MaxTime;

		var dDateWithAddedMinutes = new Date(product);
		// console.log(dDateWithAddedMinutes);
		// console.log('------------');
		dDateWithAddedMinutes.setHours(
			dDateWithAddedMinutes.getHours(),
			dDateWithAddedMinutes.getMinutes() + MaxTime,
			0,
			0
		);
		var dNow = new Date();
		// console.log(dDateWithAddedMinutes);
		// console.log(dNow);

		if (dNow.getTime() > dDateWithAddedMinutes.getTime()) {
			//console.log("Red colour");
			return (
				<td
					style={{ backgroundColor: "rgb(243, 23, 0, 0.32)", fontSize: "18px" }}
				>
					{product}
				</td>
			);
		} else {
			//console.log("Green colour");
			return (
				<td
					style={{ backgroundColor: "rgb(55, 180, 0,0.32)", fontSize: "12px" }}
				>
					{product}
				</td>
			);
		}
	};

	componentDidCatch() {
		console.log("Caught error");
		this.setState({ hasError: true });
	}

	gridWidth = "80%";

	//// This is used when you add the width variable to the <Column> of the Grid   //<Column field="JobName" title="Job Name" width={this.setPercentage(20)}/>
	//gridWidth = 600;
	// setPercentage = (percentage) => {
	//     return Math.round(this.gridWidth / 100) * percentage;
	// }

	render() {
		if (this.state.hasError) {
			return <div>The database is being restored</div>;
		} else {
			return (
				<div
					style={{ height: "80%", display: "flex", justifyContent: "center" }}
				>
					<Grid
						style={{
							height: "80%",
							width: this.gridWidth,
							// padding: "0.25rem",
							lineHeight: "0.5",
							fontSize: "small",
						}}
						filterable={false}
						sortable={false}
						pageable={false}
						{...this.state.dataState}
						{...this.state.products}
						onDataStateChange={this.dataStateChange}
					>
						<GridColumn
							field="JobName"
							title="Job Name"
							className="customClass"
						/>
						{/* format="{0:MMM yyyy}"           format="{0:D}" */}
						<GridColumn
							className="customClass"
							field="LastRun"
							title="Last Run"
							cell={this.cellWithBackGround}
						/>
						<GridColumn
							field="MaxTime"
							title="Max Time"
							className="customClass"
						/>
					</Grid>

					<DataLoader
						dataState={this.state.dataState}
						onDataRecieved={this.dataRecieved}
					/>
				</div>
			);
		}
	}
}
