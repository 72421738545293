import React, { Component } from "react";
import CarterChart from "./Dashboard/CarterChart";
import CarterGrid from "./Dashboard/CarterGrid";
// import CarterGridExample from "./Dashboard/CarterGridExample";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import styled from "styled-components";
import { generateMedia } from "styled-media-query";
import Header from "./Header";

const handleChange = (event, newValue) => {
	console.log("handler");
	// setValue(newValue);
};

class Landing extends Component {
	state = {
		tabIndex: 0,
		seconds: 0,
	};

	componentWillUnmount() {
		clearInterval(this.interval);
	}

	componentDidMount() {
		this.interval = setInterval(() => this.tick(), 120000);
	}

	tick() {
		this.setState((prevState) => ({
			seconds: prevState.seconds + 1,
		}));

		// console.log("before running get data");

		//var ts = new Date();
		//console.log(ts.toISOString());

		//console.log(this.state.tabIndex);
		var newTabIndex = null;

		if (this.state.tabIndex <= 1) {
			newTabIndex = this.state.tabIndex + 1;
		} else {
			newTabIndex = 0;
		}

		this.setState({ tabIndex: newTabIndex });
	}

	render() {
		//console.log('test');
		return (
			<div>
				<br />
				<br />
				<Header></Header>
				<LandingContainer>
					<div style={{ paddingTop: "0.3rem" }}></div>
					<Tabs
						className="tabs"
						selectedIndex={this.state.tabIndex}
						onSelect={(tabIndex) => this.setState({ tabIndex })}
					>
						<TabList className="tab-nav-container">
							<Tab
								className={`${
									this.state.tabIndex === 0 ? "tab-selected active" : null
								}`}
							>
								<p>
									<strong>Carter Jobs</strong>
								</p>
							</Tab>
							<Tab
								className={`${
									this.state.tabIndex === 1 ? "tab-selected active" : null
								}`}
							>
								<p>
									<strong>Deal Requests | Call Recordings</strong>
								</p>
							</Tab>
							<Tab
								className={`${
									this.state.tabIndex === 2 ? "tab-selected active" : null
								}`}
							>
								<p>
									<strong>Stock info</strong>
								</p>
							</Tab>
						</TabList>

						{/* Tabs content */}

						<TabPanel>
							<div style={{ paddingTop: "0.4rem" }}></div>
							<CarterGrid />
							{/* <CarterGridExample /> */}
						</TabPanel>
						<TabPanel>
							<div className="landingGrid">
								<div>
									<CarterChart
										ChartAPI_Name="QuoteRequestsPerDay"
										// ChartTitle="Quote Requests Per Day"
										ChartSeriesItem_name="Quote Requests Per Day - Distinct User"
										ChartSeriesItem_type="line"
										ChartSeriesItem_field="DistinctUser" //QuoteCount
										ChartSeriesItem_categoryField="Date"
										ChartSeriesItem_color="#f8b133"
										ChartLegend_position="top"
										ChartLegend_orientation="horizontal"
										ChartCategoryAxis_reverse="true"
									/>
								</div>
								<div>
									{/* <Chart3 /> */}
									<CarterChart
										ChartAPI_Name="CallRecordingsHourly"
										// ChartTitle="Quote Requests per Month"
										ChartSeriesItem_name="Call Recordings - Hourly"
										ChartSeriesItem_type="line"
										ChartSeriesItem_field="Count"
										ChartSeriesItem_categoryField="Hour"
										ChartSeriesItem_color="#67c96a"
										ChartLegend_position="top"
										ChartLegend_orientation="horizontal"
										ChartCategoryAxis_reverse="true"
									/>
								</div>
								<div>
									{/* <Chart2 /> */}
									<CarterChart
										ChartAPI_Name="CallRecordingsDaily"
										// ChartTitle="Quote Requests per Month"
										ChartSeriesItem_name="Call Recordings - Daily"
										ChartSeriesItem_type="line"
										ChartSeriesItem_field="Count" //QuoteCount
										ChartSeriesItem_categoryField="Day"
										ChartSeriesItem_color="#67c96a" //RGB: 178, 192, 209
										ChartLegend_position="top"
										ChartLegend_orientation="horizontal"
										ChartCategoryAxis_reverse="true"
									/>
								</div>
								<div>
									{/* <Chart1></Chart1> */}
									<CarterChart
										ChartAPI_Name="QuoteRequestsPerMonth"
										// ChartTitle="Quote Requests per Month"
										ChartSeriesItem_name="Quote Requests per Month - Distinct User"
										ChartSeriesItem_type="column"
										ChartSeriesItem_field="DistinctUser" //QuoteCount
										ChartSeriesItem_categoryField="Date"
										ChartSeriesItem_color="#f8b133" //RGB: 178, 192, 209
										ChartLegend_position="top"
										ChartLegend_orientation="horizontal"
										ChartCategoryAxis_reverse="true"
									/>
								</div>
								<div>
									<CarterChart
										ChartAPI_Name="UsersCreatedByDay"
										// ChartTitle="Quote Requests Per Day"
										ChartSeriesItem_name="New Users Created Per Day"
										ChartSeriesItem_type="line"
										ChartSeriesItem_field="Registered"
										ChartSeriesItem_categoryField="CreateDate"
										ChartSeriesItem_color="#B2C0D1"
										ChartLegend_position="top"
										ChartLegend_orientation="horizontal"
										ChartCategoryAxis_reverse="true"
									/>
								</div>
								<div>
									{/* <Chart1></Chart1> */}
									<CarterChart
										ChartAPI_Name="UsersCreatedByMonth"
										// ChartTitle="Quote Requests per Month"
										ChartSeriesItem_name="New Users Created per Month"
										ChartSeriesItem_type="column"
										ChartSeriesItem_field="count"
										ChartSeriesItem_categoryField="CreateDate"
										ChartSeriesItem_color="#B2C0D1"
										ChartLegend_position="top"
										ChartLegend_orientation="horizontal"
										ChartCategoryAxis_reverse="true"
									/>
								</div>
							</div>
						</TabPanel>
						<TabPanel>
							{/* <div>empty space</div> */}
							<div className="landingGrid">
								<div>
									<CarterChart
										ChartAPI_Name="DealerShipStockEntriesAndUpdates"
										// ChartTitle="Quote Requests Per Day"
										ChartSeriesItem_name="Dealership Stock Entries and Updates"
										ChartSeriesItem_type="line"
										ChartSeriesItem_field="NewEntries" //QuoteCount
										ChartSeriesItem_categoryField="Date"
										ChartSeriesItem_color="#f8b133"
										ChartLegend_position="top"
										ChartLegend_orientation="horizontal"
										ChartCategoryAxis_reverse="true"
									/>
								</div>
							</div>
						</TabPanel>
					</Tabs>
				</LandingContainer>
			</div>
		);
	}
}

export default Landing;

// Media
const customMedia = generateMedia({
	phone: "375px",
	tablet: "640px",
	lgTablet: "740px",
});

// Main content Container
const LandingContainer = styled.div`
	.landingGrid {
		display: grid;
		align-items: center;
		// grid-template-columns: repeat(3, 1fr);
		// grid-template-columns: repeat(auto-fit, minmax(500px, 2fr));
		grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
		// grid-gap: 5px;
		// padding: 0.5rem;
		background-color: #fcfcfc;
		${customMedia.lessThan("tablet")`
			display: grid;
			align-items: left;
			grid-template-columns: repeat(1, 1fr);
			// grid-gap: 0.1rem;
			// padding: 0.1rem;
			// background-color: #606366
		`}
	}

	.tabs {
		padding-top: 1rem;
	}

	.tab-nav-container {
		display: grid;
		justify-content: center;
		align-content: center;
		text-align: center;
		font-size: 12px;
		grid-template-columns: repeat(3, 0.5fr);
		//grid-template-columns: repeat(auto-fit, 0.5fr);
		// grid-gap: 0.5rem;
		// padding: 0.1rem;
		list-style: none;
		color: #8d8f90;
		// background: #f3f2f2;
		margin: 0 5rem;
		${customMedia.lessThan("tablet")`
			display: grid;
			align-items: left;
			grid-template-columns: repeat(1, 1fr);
			grid-gap: 0.1rem;
			padding: 0.1rem;
			background-color: #606366;
			font-size: 8px;
		`}
	}

	.active {
		border-bottom: 4px solid #f8b133;
	}
`;
