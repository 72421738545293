import React, { Component } from "react";

import {
	Chart,
	ChartSeries,
	ChartSeriesItem,
	// ChartValueAxis, ChartValueAxisItem,
	ChartCategoryAxis,
	ChartCategoryAxisItem,
	ChartArea,
	ChartTitle,
	ChartTooltip,
	ChartLegend,
} from "@progress/kendo-react-charts";

import "hammerjs";

// const valueAxisLabels = {
//   padding: 2,
//   font: 'bold 12px Arial, sans-serif'
// };
const seriesLabels = {
	visible: true, // Note that visible defaults to false
	padding: 2,
	font: "bold 12px Arial, sans-serif",
};

const renderTooltip = ({ point }) => (
	<span>
		{point.category} <br></br> {point.value}
	</span>
);

// const customAggregate = (values, series, dataItems, category) => (values.reduce((n, acc) => acc + n, 0));

export default class QuoteCount extends Component {
	state = null;

	constructor(props) {
		super(props);
		this.state = { seconds: 0 };
	}

	tick() {
		this.setState(prevState => ({
			seconds: prevState.seconds + 1,
		}));

		// console.log("before running get data");

		var ts = new Date();
		console.log(ts.toISOString());

		//this.getChartData();
  }
  
	componentWillUnmount() {
		clearInterval(this.interval);
	}

	componentDidMount() {
		//console.log('start');
		this.getChartData();

		//this.interval = setInterval(() => this.tick(), 60000);
	}

	getChartData() {
		let that = this;
		
		// console.log(
		// 	`https://localhost:44309/GetChartData/${this.props.ChartAPI_Name}`
		// );

		const header = new Headers()
        //header.set('Authorization', 'Basic ' + base64.encode(username + ':' + password))
        header.set('__react_source_origin', 'http://devadmin.heycarter.co.za')
        header.set('Content-Type', 'application/json; charset=utf-8')


		// headers: {
		// 	"Content-Type": "application/json; charset=utf-8",
		// },

		//fetch(`https://localhost:44309/GetChartData/${this.props.ChartAPI_Name}`, {
			fetch(`http://devadminapi.heycarter.co.za/GetChartData/${this.props.ChartAPI_Name}`, {
			method: "GET",
			dataType: "JSON",
			headers: header
		})
			.then(resp => {
				//console.log("response:" + resp.json());
				//console.log("gets here1");
				return resp.json();
			})
			.then(data => {
				//console.log("gets here");
				that.setState(data);

				//try the below to catch when the DB is being restored
				// if (data !== undefined){
				// 	that.setState(data);
				// }
				// else
				// {
				// 	console.log("data is undefined");
				// }

				
				
				// console.log(this.props.ChartSeriesItem_name)
				// console.log(data)
				
			})
			.catch(error => {
				console.log(error, "catch the hoop");
			});
	}

	render() {
		// console.log('CarterChart');
		// console.log(this.props);
		return (
			<Chart>
				<ChartTooltip />
				if (this.props.ChartTitle === ""? :{" "}
				<ChartTitle text={this.props.ChartTitle} />)
				<ChartLegend
					position={this.props.ChartLegend_position}
					orientation={this.props.ChartLegend_orientation}
				/>
				<ChartArea width={450} height={350} />
				<ChartSeries>
					<ChartSeriesItem
						type={this.props.ChartSeriesItem_type}
						// style="smooth"
						data={this.state}
						labels={seriesLabels}
						field={this.props.ChartSeriesItem_field}
						categoryField={this.props.ChartSeriesItem_categoryField}
						color={this.props.ChartSeriesItem_color}
						name={this.props.ChartSeriesItem_name}
						//aggregate={customAggregate}
					/>
				</ChartSeries>
				<ChartCategoryAxis>
					<ChartCategoryAxisItem
						maxDivisions={12}
						labels={{ rotation: 35 }}
						reverse={this.props.ChartCategoryAxis_reverse}
					/>
				</ChartCategoryAxis>
				<ChartTooltip render={renderTooltip} />
			</Chart>
		);
	}
}
