import Axios from "axios";
import React, { useState } from "react";
import logo from "../images/Hey-Carter-Logo-about.png";
import { useHistory } from "react-router-dom";
//import { getUserAuth } from "./../api";

function Login() {
	const [formUsername, setformUsername] = useState("");
	const [formPassword, setFormPassword] = useState("");

	const history = useHistory();

	async function login(e) {
		e.preventDefault();

		const loginData = {
			Username: formUsername,
			Password: formPassword,
		};

		// mmaadmin   !HeyCarterData
		//await Axios.post("https://localhost:44309/GetUserAuth", loginData);
		await Axios.post(
			"http://devadminapi.heycarter.co.za/GetUserAuth",
			loginData
		)
			.then(function (response) {
				console.log(response.data.Result);
				if (response.data.Result === "Success") {
					history.push("/Landing");
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}

	return (
		<div className="auth-form">
			<div>
				<img src={logo}></img>
			</div>
			<h2>Log in</h2>
			<form className="form" onSubmit={login}>
				<label htmlFor="form-username">Username</label>
				<input
					id="form-username"
					value={formUsername}
					onChange={(e) => setformUsername(e.target.value)}
				/>

				<label htmlFor="form-password">Password</label>
				<input
					id="form-password"
					type="password"
					value={formPassword}
					onChange={(e) => setFormPassword(e.target.value)}
				/>

				<button className="btn-submit" type="submit">
					Log in
				</button>
			</form>
		</div>
	);
}

export default Login;
