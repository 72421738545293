import React from 'react';
import ReactDOM from 'react-dom';
import { toODataString } from '@progress/kendo-data-query';

export class DataLoader extends React.Component {
    //baseUrl = 'https://demos.telerik.com/kendo-ui/service-v4/odata/Products?$count=true&';
    baseUrl = 'http://devadminapi.heycarter.co.za/GetChartData/JobsGrid';
    init = { method: 'GET', accept: 'application/json', headers: {} };

    lastSuccess = '';
    pending = '';

    componentDidCatch() {
        console.log("Caught error");
        this.setState({hasError:true});
    }

    requestDataIfNeeded = () => {
        if (this.pending || toODataString(this.props.dataState) === this.lastSuccess) {
            return;
        }
        this.pending = toODataString(this.props.dataState);
        // fetch(this.baseUrl + this.pending, this.init)
        fetch(this.baseUrl, this.init)        
            .then(response => response.json())
            .then(json => {
                this.lastSuccess = this.pending;
                this.pending = '';
                var JsonLength = Object.keys(json).length;
                 //console.log(JsonLength);
                 console.log(json);
                 //console.log("here for grid 1");
                 if (JsonLength > 0){
                    if (toODataString(this.props.dataState) === this.lastSuccess) {
                        this.props.onDataRecieved.call(undefined, {
                            data: json,
                            // total: json['@odata.count']
                        });
                        //console.log("here for grid 3");
                    } else {
                        //console.log("here for grid 2");
                        this.requestDataIfNeeded();
                    }
                 }
                 else {
                     console.log("JsonLength = 0");
                 }
            });
    }

    render() {
        this.requestDataIfNeeded();
        return this.pending && <LoadingPanel />;
    }
}


class LoadingPanel extends React.Component {
    render() {
        const loadingPanel = (
            <div className="k-loading-mask">
                <span className="k-loading-text">Loading</span>
                <div className="k-loading-image"></div>
                <div className="k-loading-color"></div>
            </div>
        );

        const gridContent = document && document.querySelector('.k-grid-content');
        return gridContent ? ReactDOM.createPortal(loadingPanel, gridContent) : loadingPanel;
    }
}
