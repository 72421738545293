import React from "react";
// import styled from 'styled-components';
import {
	AppBar,
	Toolbar,
	IconButton,
	makeStyles,
	Typography,
	Menu,
	MenuItem,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import { CarterLogo } from "../Components/UI/icons";
import MenuIcon from "@material-ui/icons/Menu";

const useStyles = makeStyles({
	header: {
		backgroundColor: "#f8b133",
		display: "flex",
		// boxShadow: "none",
		// padding: "0 0",
		// borderBottom: "2px solid #606366",
	},
});

function Header() {
	const classes = useStyles();

	return (
		<AppBar position="fixed" className={classes.header}>
			{/* <Toolbar style={{ display: "flex" }}> */}
			<Toolbar>
				<IconButton
					edge="start"
					className={classes.menuButton}
					color="inherit"
					aria-label="menu"
				>
					<MenuIcon />
				</IconButton>
				<div style={{ flexGrow: 1 }}>
					<div>
						<CarterLogo link={true} linkTo="/" width="60px" height="60px" />
					</div>
				</div>
				<Typography variant="h6">Carter Admin</Typography>

				{/* <Link to="/the_team">
					<Button color="inherit">link 1</Button>
				</Link>
				<Link to="/the_matches">
					<Button color="inherit">link 2</Button>
				</Link> */}
			</Toolbar>
		</AppBar>
	);
}

export default Header;
