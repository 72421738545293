import React from 'react';
import { Link } from 'react-router-dom';
import carterlogo from '../Resources/LogoGray60x60.png';

export const CarterLogo = (props) => {

    const template = <div
        className="header_img_size"
        style={{
            width: props.width,
            height: props.height,
            background:`url(${(carterlogo)}) no-repeat`
        }}
    ></div>

    if(props.link){
        return (
            <Link to={props.linkTo} className="link_logo">
                {template}
            </Link>
        )
    } else {
        return template
    }
}
